import {
    ToastContainer,
    ToastOptions,
    toast,
    TypeOptions,
  } from "react-toastify";
  import "react-toastify/dist/ReactToastify.css";
  
  /****************************
   * Notification component
   * include the notification component in App.tsx component
   * to ensure that the toast notifications can be displayed
   * anywhere within the application.
   * this will substitute the alert() default notification from the browser
   * https://fkhadra.github.io/react-toastify/introduction
   ***************************/
  const Notification: React.FC = () => {
    return <ToastContainer />;
  };
  
  export const notify = (
    message: string,
    type: TypeOptions = "info",
    options?: ToastOptions,
  ) => {
    const defaultOptions: ToastOptions = {
      position: "top-center",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: false,
      draggable: true,
      progress: undefined,
      ...options,
    };
  
    switch (type) {
      case "success": // green color
        toast.success(message, defaultOptions);
        break;
      case "error": // red color
        toast.error(message, defaultOptions);
        break;
      case "warning": // yellow color
        toast.warn(message, defaultOptions);
        break;
  
      default: // blue color
        toast.info(message, defaultOptions);
        break;
    }
  };
  
  export default Notification;
  