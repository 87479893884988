import React from "react";
import { Navigate, useLocation } from "react-router-dom";
import { useAppSelector } from "./redux/hooks";
import { PropsIRequest } from "./interface";

/*******************************
 * Request authentication
 *****************************/
export default function RequestAuth({ children }: PropsIRequest) {
  const currentUser = useAppSelector((state) => state.user.userLogin);
  let location = useLocation();

  if (!currentUser) {
    return <Navigate to={"/"} state={{ from: location }} replace />;
  }
  
  return children;
}
