import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { User } from "./interface";

const initialState: User = {
  userLogin: false,
  currentUser: [],
};

export const userSlice = createSlice({
  name: "user",
  initialState,

  reducers: {
    onCurrentUserInfo: (state, action: PayloadAction<Array<any>>) => {
      state.currentUser = action.payload;
    },
    onUserLogin: (state, action: PayloadAction<boolean>) => {
      state.userLogin = action.payload;
    },
  },
});
export const { onCurrentUserInfo, onUserLogin } = userSlice.actions;
export default userSlice.reducer;

// ref: https://react-redux.js.org/using-react-redux/usage-with-typescript#define-root-state-and-dispatch-types
