import React, { Suspense, lazy, useEffect, useState } from "react";
import { Route, Routes, BrowserRouter as Router } from "react-router";
import Main from "./components/page-one/Main";
import RequestAuth from "./RequestAuth";
import { paths } from "./interface";
import Notification from "./components/react-toatify/ReactToastify";
// import { BeforeInstallPromptEvent } from "./pwa";

const Courses = lazy(() => import("./components/page-one/courses/Courses"));
const Information = lazy(
  () => import("./components/page-one/information/Information"),
);
const Login = lazy(() => import("./components/login/login/Login"));
const UserDashboard = lazy(
  () => import("./components/user-dashboard/UserDashboard"),
);


/////////////////////////
// App,tsx
function App() {
  // Disable right-click using useEffect hook
  useEffect(() => {
    const handleContextMenu = (e: any) => {
      e.preventDefault();
    };

    document.addEventListener("contextmenu", handleContextMenu);

    return () => {
      document.removeEventListener("contextmenu", handleContextMenu);
    };
  }, []);


  //  <Notification />  raact-toattify enable the notification work witin the application
  return (
    <>
      <Notification />
      <Router>
        <Suspense
          fallback={
            <div
              style={{ width: "100%", textAlign: "center", marginTop: "1em" }}
            >
              Loading.....
            </div>
          }
        >
          <Routes>
            <Route path={paths.main} element={<Main />} />
            <Route path={paths.login} element={<Login />} />
            <Route
              path={paths.userdashboard}
              element={
                <RequestAuth>
                  <UserDashboard />
                </RequestAuth>
              }
            />
          </Routes>
        </Suspense>
      </Router>
    </>
  );
}

export default App;
