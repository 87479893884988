interface PropsI {
    main: string;
    login: string;
    userdashboard: string;
   
  }
  
  // readonly from the interface variables 
  // react-router-dom
  // exported to App.tsx and SignUp.tsx, Login.tsx
  export const paths: Readonly<PropsI> = {
    main: "/",
    login: "/login",
    userdashboard: "/userdashboard"
   
  };

  export interface PropsIRequest {
    children: any;
  }